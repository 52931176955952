<template>
  <cw-page
    icon="check"
    class="cw-business-loan-signed"
  >
    <template #hero-title>
      <translate>
        The loan application has been signed
      </translate>
    </template>
    <v-row>
      <v-col
        class="
          cw-border-container
          cw-border-container--primary
        "
        cols="12"
        md="8"
      >
        <p>
          <translate>
            Your business's signature clause is already filled in for the loan application. We have
            received the required signatures for the loan agreement and approved the loan for
            your company.
          </translate>
        </p>
      </v-col>
    </v-row>
  </cw-page>
</template>

<script>
export default {
  name: 'CwBusinessLoanSigned',
};
</script>
